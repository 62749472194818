import React from "react";

export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Meet the Team</h2>
          <p>
          Our team of highly skilled professionals has years of experience in providing top-notch IT solutions to businesses of all sizes.
          </p>          
        </div>
        
        <div id="row">
          <p className="col-md-8 col-md-offset-2 "><h3 className={"section-heading"}>ODOO TEAM</h3></p>
          {props.data1
            ? props.data1.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-6 col-sm-6 team">
                  <div className="thumbnail">
                    {" "}
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                      <p>{d.description}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
        
        <div id="row">
          <p className="col-md-8 col-md-offset-2"><h3 className={"section-heading"}>Commerce/Website/Full Stack developers</h3></p>
          {props.data2
            ? props.data2.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-6 col-sm-6 team">
                  <div className="thumbnail">
                    {" "}
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                      <p>{d.description}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
        <div id="row">
          <p className="col-md-8 col-md-offset-2"><h3 className={"section-heading"}>Data & Analytics</h3></p>
          {props.data3
            ? props.data3.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-6 col-sm-6 team">
                  <div className="thumbnail">
                    {" "}
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                      <p>{d.description}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
